import { useEffect, useState } from "react";
import { getAllPeople } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress } from "@mui/material";
import UserRowForSettingRole from "./UserRowForSettingRole";

const SetRoles = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [people, setPeople] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getAllPeople(t)
        .then((p) => {
          setPeople(p.people);
          setLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }, []);

  return loaded ? (
    <Box>
      {people.map((p) => (
        <UserRowForSettingRole person={p} />
      ))}
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default SetRoles;
