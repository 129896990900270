import { Avatar, Box, MenuItem, Select, Typography } from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import { newPersonModalStyles } from "../../pages/pagesStyles";

const UserRowForSettingRole = (props) => {
  const { person } = props;
  const updateUser = async () => {};
  const modalClasses = newPersonModalStyles();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#f8f8f8",
        marginBottom: "0.4rem",
        padding: "0.4rem",
        paddingTop: "0.8rem",
        paddingBottom: "0.8rem",
        borderRadius: "0.8rem",
      }}
    >
      <Typography sx={{ marginLeft: "1rem" }}>
        {person.firstName} {person.lastName}
      </Typography>
      <Box sx={{ marginRight: "1rem" }}>
        <Select
          variant="standard"
          onChange={(e) => {
            updateUser(e.target.value);
          }}
          value={person.role}
          disableUnderline={true}
        >
          <MenuItem value="staff">
            {" "}
            <Box className={modalClasses.typeItemContainer}>
              <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                <PersonIcon color="white" fontSize="small" />
              </Avatar>
              Staff
            </Box>
          </MenuItem>
          <MenuItem value="managers">
            {" "}
            <Box className={modalClasses.typeItemContainer}>
              <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                <SupervisorAccountIcon color="white" fontSize="small" />
              </Avatar>
              Manager
            </Box>
          </MenuItem>
          <MenuItem value="administrators">
            <Box className={modalClasses.typeItemContainer}>
              <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                <AdminPanelSettingsIcon color="white" fontSize="small" />
              </Avatar>
              Administrator
            </Box>
          </MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default UserRowForSettingRole;
