import { useAuth0 } from "@auth0/auth0-react";
import { bookingsSettings } from "../bookingsComponents/bookingsComponentStyles";
import { useContext, useEffect, useState } from "react";
import { getAvailableTimes, updateAvailableData } from "../../apiHelper";
import { Box, CircularProgress, Typography } from "@mui/material";
import SettingsAvailableTimesComponent from "../bookingsComponents/SettingsAvailableTimesComponent";
import OnboardingContext from "./OnboardingContext";

const OnboardingAvailableTimes = () => {
  const classes = bookingsSettings();
  const { user, getAccessTokenSilently } = useAuth0();
  const [changed, setChanged] = useState(false);
  const { setNextFunction } = useContext(OnboardingContext);

  const [availableData, changeAvailableData] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    active: [],
  });

  const onNext = () => {
    getAccessTokenSilently().then((t) => {
      updateAvailableData(t, user.sub, availableData);
    });
  };

  const [loaded, setLoaded] = useState(false);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getAvailableTimes(t, { externalId: user.sub })
        .then((a) => {
          changeAvailableData(a);
        })
        .finally(() => setLoaded(true));
    });
  }, []);
  useEffect(() => {
    setNextFunction(() => onNext);
  }, [availableData]);
  return (
    <Box className={classes.outerContainer}>
      <Box>
        <Typography
          sx={{
            margin: 0,
            fontSize: 1.4,
            fontWeight: 600,
            marginBottom: "1rem",
          }}
        >
          Block out some time every week to be available to talk to team
          members.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loaded ? (
          daysOfWeek.map((d, i) => (
            <SettingsAvailableTimesComponent
              key={i}
              day={d}
              availableData={availableData}
              changeAvailableData={changeAvailableData}
              setChanged={setChanged}
            />
          ))
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};

export default OnboardingAvailableTimes;
